<template>
  <div v-if="electrician" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <Widget v-if="electrician">
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Basisdaten</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('name')"
            label-for="name"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="electrician.name" type="text" :placeholder="$t('name')" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('street')"
            label-for="street"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="electrician.street" type="text" :placeholder="$t('street')" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('streetNumber')"
            label-for="streetNumber"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="electrician.streetNumber"
              type="text"
              :placeholder="$t('streetNumber')"
            />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('zip')"
            label-for="zip"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="electrician.zip" type="text" :placeholder="$t('zip')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('city')"
            label-for="city"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="electrician.city" type="text" :placeholder="$t('city')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('certificateNumber')"
            label-for="certificateNumber"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="electrician.certificateNumber"
              type="text"
              :placeholder="$t('certificateNumber')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('registeredIn')"
            label-for="registeredIn"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="electrician.registeredIn"
              type="text"
              :placeholder="$t('registeredIn')"
            />
          </b-form-group>

          <legend>
            <strong>Upload</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('projectAttachmentType')"
            label-for="projectAttachmentType"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              :placeholder="$t('electricianAttachmentType')"
              v-model="selectedElectricianAttachmentType"
              id="electricianAttachmentType"
              :options="getElectricianAttachmentTypes"
              :allow-empty="false"
              :show-labels="false"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ $t(option) }}
              </template>
              <template slot="option" slot-scope="{ option }">
                {{ $t(option) }}
              </template>
            </multiselect>

            <DropzoneUpload
              class="mt-2"
              :complete="isAttachmentUploadComplete"
              :parentId="electrician.id"
              :parentType="selectedElectricianAttachmentType"
              :uploadCallback="uploadElectricianAttachment"
              :disabled="isUploadDisabled"
              v-on:dropzone-upload:reset="isAttachmentUploadComplete = false"
            />
          </b-form-group>

          <legend>
            <strong>{{ $t('certificateAttachments') }}</strong>
          </legend>
          <AttachmentList
            :attachments="electrician.certificateAttachments"
            attachmentType="certificateAttachments"
            v-on:attachment-list:delete="onDeleteElectricianAttachment"
          ></AttachmentList>
          <legend>
            <strong>{{ $t('signatureAttachments') }}</strong>
          </legend>
          <AttachmentList
            :attachments="electrician.signatureAttachments"
            attachmentType="signatureAttachments"
            v-on:attachment-list:delete="onDeleteElectricianAttachment"
          ></AttachmentList>
          <legend>
            <strong>{{ $t('stampAttachments') }}</strong>
          </legend>
          <AttachmentList
            :attachments="electrician.stampAttachments"
            attachmentType="stampAttachments"
            v-on:attachment-list:delete="onDeleteElectricianAttachment"
          ></AttachmentList>
        </b-col>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>{{ $t('powerCompany') }} - {{ $t('guestEntryNumber') }}</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('powerCompany_add')"
            label-for="powerCompany"
            label-class="text-md-left"
            :label-cols="2"
            breakpoint="md"
            class="d-flex"
          >
            <multiselect
              :placeholder="$t('select')"
              v-model="selectedPowerCompany"
              id="powerCompany"
              :options="getPowerCompanies"
              :show-labels="false"
              track-by="company"
              label="company"
              @input="onAddPowerCompany"
            >
            </multiselect>
          </b-form-group>

          <ElectricianPowerCompanies :entries="electrician.powerCompanies" />

          <legend>
            <strong>Ansprechpartner</strong>
            <div class="button-right">
              <button class="btn btn-sm btn-success right" @click.prevent="onAddContact">
                <i class="fa fa-plus" />
              </button>
            </div>
          </legend>

          <div
            class="electricianContacts"
            v-for="(contact, index) in electrician.contacts"
            :key="index"
          >
            <b-form-group
              horizontal
              :label="$t('firstname')"
              label-for="firstname"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input
                v-model="contact.firstname"
                type="text"
                :placeholder="$t('firstname')"
              />
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('lastname')"
              label-for="lastname"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input v-model="contact.lastname" type="text" :placeholder="$t('lastname')" />
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('position')"
              label-for="position"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input v-model="contact.position" type="text" :placeholder="$t('position')" />
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('phone')"
              label-for="phone"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input v-model="contact.phone" type="text" :placeholder="$t('phone')" />
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('mobile')"
              label-for="mobile"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input v-model="contact.mobile" type="text" :placeholder="$t('mobile')" />
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('email')"
              label-for="email"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input v-model="contact.email" type="text" :placeholder="$t('email')" />
            </b-form-group>
            <i class="fa fa-trash-o trash-contact" @click="onTrashContact(index)" />
            <hr />
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12" md="12" sm="12">
          <ButtonBar
            :showDeleteButton="buttons.showDeleteButton"
            :model="electrician"
            modelEditRoute="ElectricianEditPage"
            modelListRoute="ElectriciansPage"
            modelRouteParamName="electricianNumber"
            :updateCallback="updateElectrician"
            :createCallback="createElectrician"
            :deleteCallback="deleteElectrician"
            :fetchCallback="fetchElectricians"
          />
        </b-col>
      </b-row>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentList from '@/components/AttachmentList';
import Multiselect from 'vue-multiselect';
import ButtonBar from '@/components/ButtonBar.vue';
import ElectricianPowerCompanies from './ElectricianPowerCompanies.vue';

export default {
  name: 'ElectricianEditPage',
  components: {
    Widget,
    Breadcrumbs,
    DropzoneUpload,
    AttachmentList,
    Multiselect,
    ButtonBar,
    ElectricianPowerCompanies,
  },
  props: {
    electricianNumber: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      number: null,
      user: {},
      selectedPowerCompany: null,
      buttonsDisabled: false,
      isAttachmentUploadComplete: false,
      selectedElectricianAttachmentType: null,
    };
  },
  computed: {
    ...mapGetters([
      'getElectrician',
      'getElectricianByNumber',
      'getElectricianAttachmentTypes',
      'getPowerCompanies',
    ]),
    buttons() {
      return {
        showDeleteButton: this.electrician && this.electrician.number !== '_new' ? true : false,
      };
    },
    electrician() {
      return this.getElectricianByNumber(this.number);
    },
    isNew() {
      return this.electrician.id === '_new';
    },
    isUploadDisabled() {
      return this.selectedElectricianAttachmentType === null ? true : false;
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: this.$t('electrician'), route: { name: 'ElectriciansPage' } },
        { name: this.electrician.number !== '_new' ? this.$t('edit') : this.$t('new') },
      ];
    },
  },
  methods: {
    ...mapActions([
      'initElectrician',
      'fetchElectricians',
      'fetchElectricianByNumber',
      'updateElectrician',
      'createElectrician',
      'deleteElectrician',
      'addEmptyElectricianContact',
      'removeElectricianContact',
      'deleteElectricianAttachment',
      'uploadElectricianAttachment',
      'fetchElectricianAttachmentTypes',
      'fetchPowerCompanies',
    ]),
    onAddContact() {
      this.addEmptyElectricianContact(this.electrician);
    },
    onTrashContact(index) {
      this.removeElectricianContact({
        updatedElectrician: this.electrician,
        index: index,
      });
    },
    onDeleteElectricianAttachment(event) {
      this.deleteElectricianAttachment({
        electricianId: this.electrician.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
    onAddPowerCompany() {
      // skip if already exists
      if (
        this.electrician.powerCompanies.some(
          (entry) => entry.powerCompany._id === this.selectedPowerCompany._id
        )
      ) {
        this.selectedPowerCompany = null;
        return;
      }

      // push to array
      this.electrician.powerCompanies.push({
        powerCompany: this.selectedPowerCompany,
        guestEntryNumber: null,
      });

      // clear dropdown
      this.selectedPowerCompany = null;
    },
  },
  async mounted() {
    if (!this.electrician && this.electricianNumber) {
      this.number = this.electricianNumber;
      this.fetchElectricianByNumber(this.number);
    } else {
      this.initElectrician();
      this.number = '_new';
    }

    await this.fetchElectricianAttachmentTypes();
    await this.fetchPowerCompanies();

    /** after upload is complete emit event to clear dropzone preview */
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadElectricianAttachment') {
          this.isAttachmentUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style scoped lang="scss">
.electricianContacts {
  text-align: right;
}
.trash-contact {
  cursor: pointer;
}
legend {
  position: relative;
}
.button-right {
  position: absolute;
  display: inline-block;
  right: 0;
  top: 2px;
}
</style>
