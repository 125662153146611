<template>
  <DataTable
    class="p-datatable-sm"
    :value="entries"
    :loading="loading"
    dataKey="id"
    :rowHover="true"
  >
    <template #empty>
      {{ $t('no_data_found') }}
    </template>
    <template #loading>
      {{ $t('loading') }}
    </template>

    <Column
      field="powerCompany.number"
      header="#"
      sortable
      :headerStyle="{ width: '60px' }"
      :bodyStyle="'text-align: center;width: 60px'"
    >
      <template #body="slotProps">
        {{ slotProps.data.powerCompany.number.toString().padStart(4, '0') }}
      </template>
    </Column>
    <Column field="powerCompany.company" :header="$t('company')" sortable> </Column>
    <Column field="guestEntryNumber" :header="$t('guestEntryNumber')" sortable>
      <template #body="slotProps">
        <InputText
          type="text"
          v-model="slotProps.data.guestEntryNumber"
          class="p-inputtext-sm p-width-lg"
          :style="{ width: '100%' }"
        />
      </template>
    </Column>
    <Column>
      <template #body="slotProps">
        <i class="fa fa-trash-o trash-contact" @click="onTrashEntry(slotProps.index)" />
      </template>
    </Column>
  </DataTable>
</template>

<script>
import { mapGetters } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';

export default {
  components: {
    DataTable,
    Column,
    InputText,
  },
  props: {
    entries: {
      type: Array,
    },
  },
  data() {
    return {
      loading: true,
      filters: {},
    };
  },
  computed: {
    ...mapGetters(['isLoading']),
  },
  methods: {
    onTrashEntry(entryIndex) {
      console.log(entryIndex);
      this.entries.splice(entryIndex, 1);
    },
  },
  async created() {
    this.loading = false;
  },
};
</script>
